
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";
import { IBoxStoreRequest } from "@/services/models/box";

@Component({
  components: {
    QrcodeVue
  }
})
export default class BoxQrCodeModal extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() box!: IBoxStoreRequest;
  close(): void {
    this.$emit("close_modal");
  }
}
