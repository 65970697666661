
import { Component, Vue, Watch } from "vue-property-decorator";
import BoxForm from "./box-form/BoxForm.vue";

import { notificationError } from "@/utils/NotificationUtils";

import { BoxListRequest } from "@/services/api/ApiBox";
import {
  IBox,
  IBoxesListRequest,
  IBoxStoreRequest
} from "@/services/models/box";
import BoxQrCodeModal from "./box-qr-code-modal/BoxQrCodeModal.vue";

@Component({
  components: {
    BoxForm,
    BoxQrCodeModal
  }
})
export default class Box extends Vue {
  loading = false;
  openModal = false;
  items: IBox[] = [];
  box_id: number | null = null;
  totalRows = 20;
  currentPage = 1;
  perPage = 10;
  pageOptions: number[] = [10, 25, 50, 100];
  filter = "";
  countPage = 0;
  pages = 0;
  qrCodeModal = false;
  selectedBox: IBox | null = null;

  fields: { key: string; label: string }[] = [
    {
      key: "id",
      label: `${this.$t("boxes.fields.id")}`
    },
    {
      key: "liters",
      label: `${this.$t("boxes.fields.liters")}`
    },
    {
      key: "status",
      label: `${this.$t("boxes.fields.status")}`
    },
    {
      key: "code",
      label: `${this.$t("boxes.fields.code")}`
    },
    {
      key: "actions",
      label: `${this.$t("boxes.fields.actions")}`
    }
  ];

  @Watch("perPage")
  onPerpageChange() {
    this.boxesList();
  }

  @Watch("currentPage")
  onCurrentPageChange() {
    this.boxesList();
  }

  async mounted(): Promise<void> {
    await this.boxesList();
  }

  async boxesList(): Promise<void> {
    this.loading = true;
    try {
      let response: IBoxesListRequest = await BoxListRequest(
        this.currentPage,
        this.perPage
      );

      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  showLicense(license: Array<string>): string {
    return license.sort().join(" - ");
  }

  showModal(): void {
    this.openModal = true;
    this.box_id = null;
  }

  closeModal(): void {
    this.openModal = false;
  }

  async showEditModal(id: number): Promise<void> {
    try {
      this.box_id = id;
      this.openModal = true;
    } catch (error) {
      console.error(error);
      notificationError(
        `${this.$t("boxes.messages.error.message.searchUser")}`
      );
    }
  }

  showPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  onFiltered(filteredItems: IBoxesListRequest[]): void {
    if (filteredItems.length > 0) {
      this.countPage = Math.ceil(this.totalRows / this.perPage);
    } else {
      this.countPage = 1;
    }
  }

  reloadPage(): void {
    this.boxesList();
  }

  showQrCode(box: IBox): void {
    this.selectedBox = box;
    this.qrCodeModal = true;
  }

  closeQrCodeModal(): void {
    this.qrCodeModal = false;
  }
}
