
import { BoxStoreRequest } from "@/services/api/ApiBox";
import { UserRegionals } from "@/services/api/ApiUser";
import { IBoxStoreRequest } from "@/services/models/box";
import { IRegional } from "@/services/models/regionals";
import { notificationSuccess } from "@/utils/NotificationUtils";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {},
  validations: {
    form: {
      liters: { required },
      regional_id: { required }
    }
  }
})
export default class BoxForm extends Vue {
  loading = false;
  form: IBoxStoreRequest = {
    liters: null
  };
  regionals: IRegional[] = [];
  @Prop() open_modal!: boolean;
  @Prop() box_id!: number;

  @Watch("open_modal", { deep: true })
  async openModal(): Promise<void> {
    if (this.open_modal) {
      await this.requestRegionals();
    } else {
      this.regionals = [];
    }
  }

  async requestRegionals(): Promise<void> {
    let regionals = await UserRegionals();
    this.regionals = regionals;
  }

  async save(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        await BoxStoreRequest(this.form);
        this.close();
        notificationSuccess(
          `${this.$t("boxes.messages.success.message.create")}`
        );
        this.$emit("reload_page");
      } catch (error) {
        console.error(error);
      }
    }
  }

  close(): void {
    this.$emit("close_modal");
    this.form = {
      liters: null
    };
  }
}
